import { Check, Loader2 } from "lucide-react";
import { toast } from "sonner";
import type { ExternalToast } from "sonner";

const toastSettings = { duration: 10000, position: "top-center" };

interface LoadingToastProps {
  text?: string;
  t: any;
}

export function LoadingToast(props: LoadingToastProps) {
  return (
    <div className="p-4 border-2 border-gray-900 rounded-xl flex flex-col gap-2 overflow-y-auto text-sm bg-purple-100 min-w-[356px] -ml-[20px]">
      <div className="flex items-center gap-1">
        <Loader2 size={16} className="animate-spin" />
        <p>{props.text ?? "Loading..."}</p>
      </div>
    </div>
  );
}

interface LoadingToastInput {
  text?: string;
  id: any;
}

export function loadingToast(input: LoadingToastInput) {
  const instance = toast.custom(
    (t) => <LoadingToast t={t} text={input.text} />,
    {
      ...toastSettings,
      id: input.id,
    } as ExternalToast,
  );
  return instance;
}

interface FailToastProps {
  text?: string;
  t: any;
}

export function FailToast(props: FailToastProps) {
  return (
    <div className="p-4 border-2 border-gray-900 rounded-xl flex flex-col gap-2 overflow-y-auto text-sm bg-red-100 min-w-[356px] -ml-[30px]">
      <p>{props.text}</p>
    </div>
  );
}

interface FailToastInput {
  text: string;
  id: any;
}

export function failToast(input: FailToastInput) {
  toast.custom((t) => <FailToast t={t} text={input.text} />, {
    ...toastSettings,
    id: input.id,
  } as ExternalToast);
}

interface OkToastProps {
  text?: string;
  t: any;
}

export function OkToast(props: OkToastProps) {
  return (
    <div className="p-4 border-2 border-gray-900 rounded-xl flex flex-col gap-2 overflow-y-auto text-sm bg-green-100 min-w-[356px] -ml-[20px]">
      <div className="flex items-center gap-1">
        <Check size={16} />
        <p>{props.text}</p>
      </div>
      <button
        className="cursor-pointer px-2 py-1 flex items-center justify-center rounded-lg bg-white border-2 border-gray-800 hover:bg-pink-200"
        onClick={() => toast.dismiss(props.t)}
      >
        OK
      </button>
    </div>
  );
}

interface okToastInput {
  text: string;
  id: any;
}
export function okToast(input: okToastInput) {
  toast.custom((t) => <OkToast t={t} text={input.text} />, {
    ...toastSettings,
    id: input.id,
  } as ExternalToast);
}
