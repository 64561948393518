import borrowerOperationsABI from "@/abis/borrower-operations.json";
import erc20ABI from "@/abis/erc20.json";
import troveManagerABI from "@/abis/trove-manager.json";
import { failToast, loadingToast, okToast } from "@/components/toasts";
import { constants, contracts } from "@/config";
import { useDebounce } from "@/hooks/use-debounce";
import { cn } from "@/util/css";
import { parseNumber } from "@/util/number";
import clsx from "clsx";
import { ConnectKitButton } from "connectkit";
import {
  Coins,
  FunctionSquare,
  Info,
  MoveRight,
  PiggyBank,
  RollerCoaster,
  Send,
  StopCircle,
  Zap,
} from "lucide-react";
import React from "react";
import { useIsomorphicLayoutEffect } from "usehooks-ts";
import { formatUnits, parseUnits } from "viem";
import {
  useAccount,
  useBalance,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { Link, Route, Router, Switch, useLocation, useParams } from "wouter";
import { TestnetWarning } from "./components/testnet-warning";
import { platformHealth, tokenHealth } from "./faker";

const currentContracts = contracts["12120"];

const liquidiationReserve = parseUnits(
  constants.liquidationReserve.toString(),
  18,
);

export function App() {
  useShutTheFuckUpAboutENS();
  return (
    <Switch>
      <Route path="/" component={Homepage} />
      <Router base="/vaults">
        <TestnetWarning />
        <TopNav activeTab="vaults" />
        <Route path="/">
          <Vaults />
          <Stats />
        </Route>
        <Route path="/:address/deposit" component={DepositVault} />
        <Route path="/:address/withdraw" component={WithdrawVault} />
        <Route path="/:address/repay" component={RepayVault} />
        <Route path="/:address/redeem" component={RedeemVault} />
        <Footer />
      </Router>
    </Switch>
  );
}

export function useShutTheFuckUpAboutENS() {
  useIsomorphicLayoutEffect(() => {
    const orig = window.console.error;
    window.console.error = function (...args) {
      if (args[0]?.name === "ChainDoesNotSupportContract") return;
      orig.apply(window.console, args);
    };
  }, []);
}

function useVaultInfo() {
  const params = useParams();
  const address = params.address as string;
  const trove = currentContracts.troves.find(
    (t) => t.collateralToken === address,
  );

  if (!trove) {
    return {
      address: "",
      name: "",
      iconUrl: "",
    };
  }

  return {
    address: trove.collateralToken,
    name: trove.name,
    iconUrl: trove.iconUrl,
  };
}

interface VaultsNavProps {
  address: string;
  activeTab: "deposit" | "withdraw" | "repay" | "redeem";
}

function VaultsNav(props: VaultsNavProps) {
  const items = [
    {
      id: "deposit",
      label: "Deposit",
      isEnabled: true,
      href: `/${props.address}/deposit`,
      isActive: props.activeTab === "deposit",
      address: props.address,
      icon: <PiggyBank size={18} />,
    },
    {
      id: "withdraw",
      label: "Withdraw",
      isEnabled: true,
      href: `/${props.address}/withdraw`,
      isActive: props.activeTab === "withdraw",
      address: props.address,
      icon: <Coins size={18} />,
    },
    {
      id: "repay",
      label: "Repay",
      isEnabled: false,
      href: `/${props.address}/repay`,
      isActive: props.activeTab === "repay",
      address: props.address,
      icon: null,
    },
    {
      id: "redeem",
      label: "Redeem",
      isEnabled: false,
      href: `/${props.address}/redeem`,
      isActive: props.activeTab === "redeem",
      address: props.address,
      icon: null,
    },
  ];

  return (
    <div className="grow-0 w-full md:w-40">
      <div className="border-2 border-black rounded-xl grid grid-cols-4 divide-x-2 md:grid-cols-1 md:divide-y-2 md:divide-x-0 divide-black bg-gray-100 overflow-hidden">
        {items
          .filter((item) => item.isEnabled)
          .map((item) => (
            <Link
              className={clsx(
                "flex items-center gap-2 py-2 px-3 inline-block hover:bg-[#FFD670]",
                {
                  "bg-[#FF9770]": props.activeTab === item.id,
                },
              )}
              to={item.href}
            >
              {item.icon}
              {item.label}
            </Link>
          ))}
      </div>
    </div>
  );
}

function DepositVault() {
  const { address: vaultAddress, name, iconUrl } = useVaultInfo();

  const { address: userAddress } = useAccount();

  // Contracts. Hardcoded for now.
  const collateralAddress = currentContracts.troves[0].collateralToken;
  const troveManager = currentContracts.troves[0].troveManager;
  const borrowerOperations = currentContracts.core.borrowerOperations;

  const approvalToastRef = React.useRef<any>(null);
  const depositToastRef = React.useRef<any>(null);

  const { data: collateralBalance, refetch: refetchCollateralBalance } =
    useBalance({
      address: userAddress,
      token: collateralAddress,
    });

  // User inputs
  const [depositQuantityInput, setDepositQuantityInput] =
    React.useState<string>("");
  const [mintRatioInput, setMintRatioInput] = React.useState<string>("");
  const [mintQuantityInput, setMintQuantityInput] = React.useState<string>("");

  // Deposit & mint input interaction
  const { data: maybeCollateralPrice, refetch: refetchMaybeCollateralPrice } =
    useContractRead({
      address: currentContracts.troves[0].troveManager,
      abi: troveManagerABI,
      functionName: "fetchPrice",
    });

  const collateralPrice = React.useMemo(() => {
    if (!maybeCollateralPrice) return 0n;
    return maybeCollateralPrice as bigint;
  }, [maybeCollateralPrice]);

  const { data: borrowingRate } = useContractRead({
    address: currentContracts.troves[0].troveManager,
    abi: troveManagerABI,
    functionName: "getBorrowingRate",
  });

  // User approval interaction
  const { data: allowance, refetch: refetchAllowance } = useContractRead({
    address: collateralAddress,
    abi: erc20ABI,
    functionName: "allowance",
    args: [userAddress, borrowerOperations],
  });

  const { config: approvalConfig } = usePrepareContractWrite({
    address: collateralAddress,
    abi: erc20ABI,
    functionName: "approve",
    args: [borrowerOperations, constants.maxAllowance],
  });

  const {
    data: approveContractResult,
    writeAsync: approveAsync,
    error: approvalError,
  } = useContractWrite(approvalConfig);

  const { isLoading: isApproving } = useWaitForTransaction({
    hash: approveContractResult ? approveContractResult.hash : undefined,
    onError() {
      failToast({
        id: approvalToastRef.current,
        text: `Failed to approve ${name}.`,
      });
    },
    onSuccess() {
      refetchDeposit();
      resetDeposit();
      refetchAllowance();
      okToast({
        id: approvalToastRef.current,
        text: `Approved ${name}.`,
      });
    },
  });

  // Faucet interaction
  const { config: faucetConfig } = usePrepareContractWrite({
    address: collateralAddress,
    abi: [
      {
        constant: true,
        name: "faucet",
        inputs: [{ name: "address", type: "address" }],
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
    functionName: "faucet",
    args: [userAddress],
  });
  const {
    data: faucetContractResult,
    writeAsync: faucetAsync,
    error: faucetError,
  } = useContractWrite(faucetConfig);
  const { isLoading: isWaitingForFaucet } = useWaitForTransaction({
    hash: approveContractResult ? approveContractResult.hash : undefined,
    onSuccess() {
      refetchCollateralBalance();
    },
  });

  // Actual deposit interaction
  const maxFee = parseUnits("5", 16);
  const depositQuantity = React.useMemo<bigint>(() => {
    return parseUnits(depositQuantityInput, 18);
  }, [depositQuantityInput]);
  const mintQuantity = React.useMemo<bigint>(() => {
    return parseUnits(mintQuantityInput, 18);
  }, [mintQuantityInput]);

  const debouncedDepositQuantity = useDebounce(depositQuantity, 500);
  const debouncedMintQuantity = useDebounce(mintQuantity, 500);

  // Get state of trove
  const { data: troveStatus } = useContractRead({
    address: troveManager,
    abi: troveManagerABI,
    functionName: "getTroveStatus",
    args: [userAddress],
  });

  const depositFn = React.useMemo(() => {
    // If trove is active, deposit & col deposit is > 0, then adjust trove
    if (
      troveStatus === constants.troveStatuses.active &&
      debouncedDepositQuantity > 0n &&
      debouncedMintQuantity > 0n
    ) {
      return {
        functionName: "adjustTrove",
        args: [
          troveManager,
          userAddress,
          maxFee,
          debouncedDepositQuantity,
          0,
          debouncedMintQuantity,
          true,
          constants.zeroAddress,
          constants.zeroAddress,
        ],
      };
    }

    // If trove is active and deposit is 0, then addColl
    if (
      troveStatus === constants.troveStatuses.active &&
      debouncedDepositQuantity > 0n &&
      debouncedMintQuantity === 0n
    ) {
      return {
        functionName: "addColl",
        args: [
          troveManager,
          userAddress,
          debouncedDepositQuantity,
          constants.zeroAddress,
          constants.zeroAddress,
        ],
      };
    }

    return {
      functionName: "openTrove",
      args: [
        troveManager,
        userAddress,
        maxFee,
        debouncedDepositQuantity,
        debouncedMintQuantity,
        constants.zeroAddress,
        constants.zeroAddress,
      ],
    };
  }, [troveStatus, debouncedDepositQuantity, debouncedMintQuantity]);

  const { config: depositConfig, refetch: refetchDeposit } =
    usePrepareContractWrite({
      address: borrowerOperations,
      abi: borrowerOperationsABI,
      functionName: depositFn.functionName,
      args: depositFn.args,
    });

  const {
    data: depositContractResult,
    writeAsync: depositAsync,
    error: depositError,
    reset: resetDeposit,
  } = useContractWrite(depositConfig);

  const { isLoading: isDepositing } = useWaitForTransaction({
    hash: depositContractResult ? depositContractResult.hash : undefined,
    onError(error) {
      failToast({
        id: depositToastRef.current,
        text: error.message,
      });
    },
    onSuccess(data) {
      refetchTrove();
      refetchCollateralBalance();
      refetchMaybeCollateralPrice();
      setDepositQuantityInput("");
      setMintQuantityInput("");
      okToast({
        id: depositToastRef.current,
        text: `Deposited ${name}.`,
      });
    },
  });

  // Get entire trove debt and collateral
  const { data: trove, refetch: refetchTrove } = useContractRead({
    address: troveManager,
    abi: troveManagerABI,
    functionName: "getTroveCollAndDebt",
    args: [userAddress],
  });

  const [userCollateral, userNetDebt] = React.useMemo(() => {
    if (!trove) return [0n, 0n];
    return Array.from(trove as [bigint, bigint]);
  }, [trove]);

  // Revoke interaction. For dev purposes only.
  const { config: revokeConfig } = usePrepareContractWrite({
    address: collateralAddress,
    abi: erc20ABI,
    functionName: "approve",
    args: [borrowerOperations, 0],
  });

  const {
    data: revokeContractResult,
    writeAsync: revokeAsync,
    error: revokeError,
  } = useContractWrite(revokeConfig);

  const { isLoading: isRevoking } = useWaitForTransaction({
    hash: revokeContractResult ? revokeContractResult.hash : undefined,
    onSuccess() {
      refetchAllowance();
    },
  });

  const userDebt = React.useMemo<bigint>(() => {
    if (userNetDebt === 0n) return 0n;
    return userNetDebt - parseUnits("200", 18);
  }, [userNetDebt, mintQuantity]);

  const collateralRatio = React.useMemo<bigint>(() => {
    const totalCollateralAmount =
      (userCollateral + depositQuantity) * (collateralPrice as bigint);
    const totalDebt = userNetDebt + mintQuantity;
    if (totalDebt === 0n) return 0n;
    return totalCollateralAmount / totalDebt;
  }, [
    troveStatus,
    userCollateral,
    userNetDebt,
    collateralPrice,
    depositQuantity,
    mintQuantity,
  ]);

  const [maxMintAmount, liquidationPrice] = React.useMemo<
    [bigint, bigint]
  >(() => {
    if (!collateralBalance) return [0n, 0n];
    if (collateralBalance.value === 0n) return [0n, 0n];

    let mintRatio = parseNumber(mintRatioInput);
    if (mintRatio === 0) {
      mintRatio = constants.mintRatio;
    }
    if (mintRatio < 150) {
      mintRatio = 150;
    }
    const totalDeposits = depositQuantity + userCollateral;
    if (totalDeposits === 0n) return [0n, 0n];

    const ratioUnits = parseUnits(mintRatio.toString(), 16);
    const totalDepositAmount = totalDeposits * (collateralPrice as bigint);
    const mintableAmount = totalDepositAmount / ratioUnits - userNetDebt;
    const precision = parseUnits("1", 18);
    const price = parseNumber(formatUnits(collateralPrice as bigint, 18));
    const borrowInput = parseUnits(mintQuantityInput, 18);

    const totalDebt = userDebt + borrowInput + liquidiationReserve;
    const debt = parseNumber(formatUnits(totalDebt, 18));

    const collateral = parseNumber(
      formatUnits(totalDepositAmount / precision, 18),
    );

    if (collateral === 0) return [0n, 0n];

    const liquidationPrice = parseUnits(
      ((debt / collateral) * 1.2 * price).toString(),
      18,
    );

    return [mintableAmount, liquidationPrice];
  }, [
    collateralBalance,
    mintRatioInput,
    collateralRatio,
    collateralPrice,
    userDebt,
    userCollateral,
    depositQuantity,
    mintQuantityInput,
  ]);

  return (
    <div className="min-h-[calc(100svh-382px)] lg:min-h-[calc(100svh-402px)] max-w-screen-lg mx-auto flex flex-col gap-16 px-4 pb-4 mt-8 mb-16 lg:mt-0 lg:px-0">
      <div className="flex flex-col md:flex-row gap-4">
        <VaultsNav activeTab="deposit" address={vaultAddress} />

        <div className="border-2 border-black rounded-xl bg-white grow">
          <div className="text-center flex justify-between items-center gap-2 bg-white rounded-t-xl overflow-hidden mt-4 mx-4 lg:mt-8 lg:mx-8">
            <div className="flex items-center justify-start gap-2">
              <img className="h-8" src={iconUrl} alt="" />
              <h2 className="text-2xl font-bold">Deposit {name}</h2>
            </div>

            <div className="flex items-center gap-1">
              <button
                className="border-2 rounded-xl px-2 py-1 hover:bg-gray-200"
                onClick={async () => {
                  if (!revokeAsync) return;
                  await revokeAsync();
                }}
              >
                Revoke
              </button>

              <button
                onClick={async () => {
                  if (!faucetAsync) return;
                  await faucetAsync();
                  setTimeout(() => {
                    refetchCollateralBalance();
                  }, 1000);
                }}
                className="border-2 rounded-xl px-2 py-1 hover:bg-gray-200"
              >
                Gib test {name}
              </button>
            </div>
          </div>
          <div className="p-4 lg:p-8 flex flex-col gap-4">
            <div>
              <div className="flex justify-between">
                <label>
                  Available:{" "}
                  <button
                    onClick={() => {
                      const collateral = parseNumber(
                        collateralBalance?.formatted ?? "",
                      );
                      setDepositQuantityInput(collateral.toString());
                    }}
                    className="text-[#FF9770] hover:text-[#FF70A6] font-bold"
                  >
                    {formatUnits(collateralBalance?.value ?? 0n, 18)} {name}
                  </button>
                </label>
                <button
                  onClick={() => {
                    const collateral = parseNumber(
                      collateralBalance?.formatted ?? "",
                    );
                    setDepositQuantityInput(collateral.toString());
                  }}
                  className="flex items-center gap-1 hover:text-[#FF70A6] text-gray-400"
                >
                  <Zap size={18} />
                  Max
                </button>
              </div>
              <input
                value={depositQuantityInput}
                onChange={(e) => setDepositQuantityInput(e.target.value)}
                placeholder="0.0"
                className="rounded-lg border-black border-2 w-full text-2xl p-2 focus:outline-none focus:border-blue-500 focus:ring-0"
                type="text"
              />
            </div>
            <div className="flex items-center gap-2">
              <div className="grow">
                <div className="flex justify-between">
                  <label className="flex items-center gap-1">
                    Mint esUSD:
                    <button
                      onClick={() => {
                        if (maxMintAmount === 0n) return;
                        const m = formatUnits(maxMintAmount, 18);
                        setMintQuantityInput(m);
                      }}
                      className="text-[#FF9770] hover:text-[#FF70A6] font-bold"
                    >
                      {maxMintAmount === 0n
                        ? "0.00"
                        : parseFloat(formatUnits(maxMintAmount, 18)).toFixed(
                            2,
                          )}{" "}
                      esUSD
                    </button>
                  </label>
                  <button
                    onClick={() => {
                      if (maxMintAmount === 0n) return;
                      const m = formatUnits(maxMintAmount, 18);
                      setMintQuantityInput(m);
                    }}
                    className="flex items-center gap-1 hover:text-[#FF70A6] text-gray-400"
                  >
                    <Zap size={18} />
                    Max
                  </button>
                </div>
                <input
                  value={mintQuantityInput}
                  onChange={(e) => setMintQuantityInput(e.target.value)}
                  placeholder="0.0"
                  className="rounded-lg border-black border-2 w-full text-2xl p-2 focus:outline-none focus:border-blue-500 focus:ring-0"
                  type="text"
                />
              </div>

              <div className="w-32 shrink">
                <div className="flex justify-end">
                  <button className="text-gray-400 hover:text-[#FF70A6] flex items-center gap-1">
                    <FunctionSquare size={18} />
                    Auto
                  </button>
                </div>
                <input
                  value={mintRatioInput}
                  onChange={(e) => {
                    setMintRatioInput(e.target.value);
                  }}
                  placeholder="150%"
                  className={cn(
                    "rounded-lg border-black border-2 w-full text-2xl p-2 focus:outline-none focus:border-blue-500 focus:ring-0",
                    {
                      "border-red-500":
                        mintRatioInput !== "" &&
                        parseNumber(mintRatioInput) < 150,
                    },
                  )}
                  type="text"
                />
              </div>
            </div>
          </div>

          <div className="px-4 lg:px-8 mb-4 lg:mb-8 w-full">
            <div className="grid grid-cols-1 lg:grid-cols-2 border-2 rounded-xl text-sm p-2 bg-gray-100/50 gap-4">
              <div className="grid grid-cols-2">
                <div className="font-bold">Total deposit</div>
                <div className="text-right">
                  {formatUnits(userCollateral + depositQuantity, 18)} {name}
                </div>
                <div className="font-bold">Net debt</div>
                <div className="text-right">
                  {parseFloat(
                    formatUnits(userNetDebt + mintQuantity, 18),
                  ).toFixed(2)}{" "}
                  esUSD
                </div>
                <div className="font-bold">Mint fee</div>
                <div className="text-right">
                  {borrowingRate
                    ? formatUnits(borrowingRate as bigint, 18)
                    : "0.0%"}
                </div>
                <div className="font-bold">Liq. reserve</div>
                <div className="text-right">200 esUSD</div>
              </div>
              <div className="grid grid-cols-2">
                <div className="font-bold">Col. ratio</div>
                <div className="text-right">
                  {parseFloat(formatUnits(collateralRatio, 16)).toFixed(2)}%
                </div>
                <div className="font-bold">Liq. price</div>
                <div className="text-right">
                  {parseFloat(formatUnits(liquidationPrice, 18)).toFixed(2)}{" "}
                  esUSD{" "}
                </div>
                <div className="font-bold">Borrow rate</div>
                <div className="text-right">
                  {borrowingRate
                    ? formatUnits(borrowingRate as bigint, 18)
                    : "0.0%"}
                </div>

                <div className="font-bold">Col. price</div>
                <div className="text-right">
                  {parseFloat(formatUnits(collateralPrice, 18)).toFixed(2)}{" "}
                  esUSD
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 lg:px-8 mb-4 lg:mb-8 flex gap-2 items-center justify-center">
            <button
              type="button"
              disabled={isApproving || allowance !== 0n}
              className={cn(
                "border-2 border-black rounded-full py-2 px-4 text-xl flex items-center gap-1",
                {
                  "bg-green-200": allowance !== 0n,
                  "bg-[#70D6FF] hover:bg-[#FF70A6]":
                    allowance === 0n && approveAsync,
                  "opacity-60": isApproving || allowance !== 0n,
                },
              )}
              onClick={async () => {
                if (!approveAsync) return;
                approvalToastRef.current = loadingToast({
                  text: `Approving ${name}...`,
                  id: "approval-toast",
                });
                await approveAsync();
              }}
            >
              {isApproving
                ? "Approving..."
                : allowance === 0n
                  ? `Approve ${name}`
                  : `Approved ${name}`}
            </button>

            <MoveRight />

            <button
              disabled={isDepositing || depositQuantity === 0n}
              onClick={async () => {
                if (!depositAsync) {
                  return;
                }
                depositToastRef.current = loadingToast({
                  text: `Depositing ${name}...`,
                  id: "deposit-toast",
                });
                await depositAsync();
              }}
              className={cn(
                "border-2 border-black rounded-full py-2 px-4 bg-[#70D6FF] hover:bg-[#FF70A6] text-xl",
                {
                  "opacity-60": depositQuantity === 0n || !depositAsync,
                },
              )}
            >
              Deposit & mint
            </button>
          </div>
        </div>

        <VaultCallout>
          <div className="flex items-center justify-start gap-1 text-left flex-col">
            <Info className="text-yellow-500" size={20} />
            <p>A minimum debt of 1800 esUSD is required.</p>
            <p>
              To mint esUSD, you are required to deposit a specific amount of
              ETH (authorizing protocol to convert ETH to stETH) or Rebase LST
              as collateral. You can then mint eUSD against your collateral up
              to a maximum collateral ratio of 150%.
            </p>
          </div>
        </VaultCallout>
      </div>
    </div>
  );
}

function WithdrawVault() {
  const { address, name, iconUrl } = useVaultInfo();
  const [withdrawQuantityInput, setWithdrawQuantityInput] =
    React.useState<string>("");
  const troveManager = currentContracts.troves[0].troveManager;
  const { address: userAddress } = useAccount();

  // Get entire trove debt and collateral
  const { data: trove, refetch: refetchTrove } = useContractRead({
    address: troveManager,
    abi: troveManagerABI,
    functionName: "getTroveCollAndDebt",
    args: [userAddress],
  });

  const { data: maybeCollateralPrice, refetch: refetchMaybeCollateralPrice } =
    useContractRead({
      address: currentContracts.troves[0].troveManager,
      abi: troveManagerABI,
      functionName: "fetchPrice",
    });

  const collateralPrice = React.useMemo(() => {
    if (!maybeCollateralPrice) return 0n;
    return maybeCollateralPrice as bigint;
  }, [maybeCollateralPrice]);

  const [userCollateral, userNetDebt] = React.useMemo(() => {
    if (!trove) return [0n, 0n];
    return Array.from(trove as [bigint, bigint]);
  }, [trove]);

  const { data: troveStatus } = useContractRead({
    address: troveManager,
    abi: troveManagerABI,
    functionName: "getTroveStatus",
    args: [userAddress],
  });

  const collateralRatio = React.useMemo<bigint>(() => {
    const maybeWithdraw = parseUnits(withdrawQuantityInput, 18);
    const totalCollateralAmount =
      (userCollateral - maybeWithdraw) * (collateralPrice as bigint);
    if (userNetDebt === 0n) return 0n;
    return totalCollateralAmount / userNetDebt; // todo: subtract reserve
  }, [
    troveStatus,
    userCollateral,
    userNetDebt,
    collateralPrice,
    withdrawQuantityInput,
  ]);

  const [maxWithdrawQuantity, liquidationPrice] = React.useMemo<
    [bigint, bigint]
  >(() => {
    if (userCollateral === 0n && userNetDebt === 0n) return [0n, 0n];

    const mintRatio = constants.mintRatio;
    const price = parseNumber(formatUnits(collateralPrice as bigint, 18));
    const collateral = parseNumber(formatUnits(userCollateral, 18));

    const debt = parseNumber(formatUnits(userNetDebt, 18));
    const collateralToMaintain = (debt * (mintRatio / 100)) / price;
    const maxWithdrawQuantity = collateral - collateralToMaintain;
    const withdrawQuantity = parseNumber(withdrawQuantityInput);

    const liquidationPrice = parseUnits(
      ((debt / (collateral - withdrawQuantity)) * 1.2).toString(),
      18,
    );
    const max = parseUnits(maxWithdrawQuantity.toString(), 18);
    return [max, liquidationPrice];
  }, [
    collateralRatio,
    collateralPrice,
    userNetDebt,
    userCollateral,
    withdrawQuantityInput,
  ]);

  const borrowerOperations = currentContracts.core.borrowerOperations;
  const { config: withdrawConfig } = usePrepareContractWrite({
    address: borrowerOperations,
    abi: borrowerOperationsABI,
    functionName: "withdrawColl",
    args: [
      troveManager,
      userAddress,
      parseUnits(withdrawQuantityInput, 18),
      constants.zeroAddress,
      constants.zeroAddress,
    ],
  });

  const {
    data: withdrawCollateralResult,
    writeAsync: withdrawCollateralAsync,
    error: withdrawCollateralError,
  } = useContractWrite(withdrawConfig);

  const { isLoading: isWithdrawing } = useWaitForTransaction({
    hash: withdrawCollateralResult ? withdrawCollateralResult.hash : undefined,
  });

  const withdrawalToastRef = React.useRef<any>(null);
  React.useEffect(() => {
    if (!withdrawalToastRef.current) {
      return;
    }

    if (isWithdrawing) {
      loadingToast({
        id: withdrawalToastRef.current,
        text: `Withdrawing ${name} still...`,
      });
      return;
    }

    if (withdrawCollateralError) {
      failToast({
        id: withdrawalToastRef.current,
        text: withdrawCollateralError.message,
      });
      withdrawalToastRef.current = null;
      return;
    }

    if (withdrawCollateralResult?.hash) {
      okToast({
        id: withdrawalToastRef.current,
        text: `Withdrew ${name}.`,
      });
      setWithdrawQuantityInput("");
    }
  }, [
    isWithdrawing,
    withdrawCollateralError,
    withdrawCollateralResult?.hash,
    withdrawalToastRef,
  ]);

  return (
    <div className="min-h-[calc(100svh-382px)] lg:min-h-[calc(100svh-402px)] max-w-screen-lg mx-auto flex flex-col gap-16 px-4 pb-4 mt-8 mb-16 lg:mt-0 lg:px-0">
      <div className="flex flex-col md:flex-row gap-4">
        <VaultsNav activeTab="withdraw" address={address} />
        <div className="border-2 border-black rounded-xl bg-white grow">
          <div className="text-center flex justify-start items-center gap-2 bg-white rounded-t-xl overflow-hidden mt-4 ml-4 lg:mt-8 lg:ml-8">
            <img className="h-8" src={iconUrl} alt="" />
            <h2 className="text-2xl font-bold">Withdraw {name}</h2>
          </div>

          <div className="p-4 lg:p-8 flex flex-col gap-4">
            <div>
              <div className="flex justify-between">
                <label>
                  Available:{" "}
                  <button
                    onClick={() => {
                      setWithdrawQuantityInput(
                        formatUnits(maxWithdrawQuantity, 18),
                      );
                    }}
                    className="text-[#FF9770] hover:text-[#FF70A6] font-bold"
                  >
                    {formatUnits(maxWithdrawQuantity, 18)} {name}
                  </button>
                </label>
                <button
                  onClick={() => {
                    setWithdrawQuantityInput(
                      formatUnits(maxWithdrawQuantity, 18),
                    );
                  }}
                  className="flex items-center gap-1 hover:text-[#FF70A6] text-gray-400"
                >
                  <Zap size={18} />
                  Max
                </button>
              </div>
              <input
                value={withdrawQuantityInput}
                onChange={(e) => setWithdrawQuantityInput(e.target.value)}
                placeholder="0.0"
                className="rounded-lg border-black border-2 w-full text-2xl p-2 focus:outline-none focus:border-blue-500 focus:ring-0"
                type="text"
              />
            </div>
          </div>

          <div className="px-4 lg:px-8 mb-4 lg:mb-8 w-full">
            <div className="grid grid-cols-1 border-2 rounded-xl text-sm p-2 bg-gray-100/50 gap-4">
              <div className="grid grid-cols-2">
                <div className="font-bold">Collateral ratio</div>
                <div className="text-right">
                  {parseFloat(formatUnits(collateralRatio, 16)).toFixed(2)}%
                </div>
                <div className="font-bold">Liquidation price</div>
                <div className="text-right">
                  {parseFloat(formatUnits(liquidationPrice, 18)).toFixed(2)}{" "}
                  esUSD
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 lg:px-8 mb-4 lg:mb-8 flex gap-2 items-center justify-center">
            <button
              onClick={async () => {
                if (!withdrawCollateralAsync) return;
                if (withdrawQuantityInput === "") return;

                withdrawalToastRef.current = loadingToast({
                  text: `Withdrawing ${name}...`,
                  id: "withdrawal-toast",
                });

                await withdrawCollateralAsync();
                await refetchTrove();
                await refetchMaybeCollateralPrice();
              }}
              className={cn(
                "border-2 border-black rounded-full py-2 px-4 bg-[#70D6FF] hover:bg-[#FF70A6] text-xl w-full",
                {
                  "opacity-60": withdrawQuantityInput === "",
                },
              )}
            >
              Withdraw
            </button>
          </div>
        </div>

        <VaultCallout>
          <div className="flex items-center justify-start gap-1 text-left flex-col">
            <Info className="text-yellow-500" size={20} />
            <p>
              Withdrawing collateral gives back your deposited LRT, lowering
              exposure to price fluctuations. Be cautious, as it may decrease
              your collateral ratio, risking liquidation if ETH value falls
              below a set limit. Regularly monitor your loan and uphold a secure
              collateral ratio to prevent potential losses.
            </p>
          </div>
        </VaultCallout>
      </div>
    </div>
  );
}

function RepayVault() {
  const { address, name, iconUrl } = useVaultInfo();
  return (
    <div className="min-h-[calc(100svh-382px)] lg:min-h-[calc(100svh-402px)] max-w-screen-lg mx-auto flex flex-col gap-16 px-4 pb-4 mt-8 mb-16 lg:mt-0 lg:px-0">
      <div className="flex flex-col md:flex-row gap-4">
        <VaultsNav activeTab="repay" address={address} />
        <div className="border-2 border-black rounded-xl bg-white grow">
          <div className="text-center flex justify-start items-center gap-2 bg-white rounded-t-xl overflow-hidden mt-4 ml-4 lg:mt-8 lg:ml-8">
            <img className="h-8" src="/esusd.svg" alt="" />
            <h2 className="text-2xl font-bold">Repay esUSD</h2>
          </div>
        </div>
        <VaultCallout>
          <div className="flex items-center justify-start gap-1 text-left flex-col">
            <Info className="text-yellow-500" size={20} />
            <p>Message here.</p>
          </div>
        </VaultCallout>
      </div>
    </div>
  );
}

function RedeemVault() {
  const { address, name, iconUrl } = useVaultInfo();
  return (
    <div className="min-h-[calc(100svh-382px)] lg:min-h-[calc(100svh-402px)] max-w-screen-lg mx-auto flex flex-col gap-16 px-4 pb-4 mt-8 mb-16 lg:mt-0 lg:px-0">
      <div className="flex flex-col md:flex-row gap-4">
        <VaultsNav activeTab="redeem" address={address} />
        <div className="border-2 border-black rounded-xl bg-white grow">
          <div className="text-center flex justify-start items-center gap-2 bg-white rounded-t-xl overflow-hidden mt-4 ml-4 lg:mt-8 lg:ml-8">
            <img className="h-8" src="/esusd.svg" alt="" />
            <h2 className="text-2xl font-bold">Redeem with esUSD</h2>
          </div>
        </div>
        <VaultCallout>
          <div className="flex items-center justify-start gap-1 text-left flex-col">
            <Info className="text-yellow-500" size={20} />
            <p>Message here.</p>
          </div>
        </VaultCallout>
      </div>
    </div>
  );
}

interface VaultCalloutProps {
  children: React.ReactNode;
}

function VaultCallout(props: VaultCalloutProps) {
  return (
    <div className="w-full md:w-48 shrink">
      <div className="border-2 border-black rounded-xl p-4 bg-gray-100 text-sm">
        {props.children}
      </div>
    </div>
  );
}

interface TopNavProps {
  activeTab: string;
}

function TopNav(props: TopNavProps) {
  return (
    <div className="max-w-screen-lg mx-auto flex flex-col px-4 lg:px-0">
      <div className="w-full flex justify-between my-4 lg:mt-8 lg:mb-12">
        <a className="flex justify-center items-center gap-2" href="/">
          <img className="h-7" src="/ebisu.svg" alt="" />
        </a>
        <div className="flex items-center font-semibold gap-4">
          <a
            className={cn({
              "border-b-2 border-black hover:text-orange-500 hover:border-orange-500":
                props.activeTab === "vaults",
            })}
            href="/vaults"
          >
            Vaults
          </a>
          <div className="relative border-b-2 border-transparent">
            Earn
            <span className="absolute text-xs opacity-40">(Soon)</span>
          </div>
        </div>
        <ConnectKitButton.Custom>
          {({
            isConnected,
            isConnecting,
            show,
            hide,
            address,
            ensName,
            chain,
          }) => {
            const shortAddr = address
              ? address.slice(0, 6) + "..." + address.slice(-4)
              : "";
            return (
              <button
                onClick={show}
                className="h-10 text-center border-2 border-black rounded-xl px-4 hover:bg-[#FF70A6] bg-[#F7F4EF]"
              >
                {isConnected ? (
                  <span className="flex items-center justify-center gap-2">
                    <StopCircle />
                    {shortAddr}
                  </span>
                ) : (
                  "Connect Wallet"
                )}
              </button>
            );
          }}
        </ConnectKitButton.Custom>
      </div>
    </div>
  );
}

function Vaults() {
  const randomTvl = Math.random() * 100;
  const randomStakedApr = Math.random() * 10;
  const randomMinted = Math.random() * 100;
  const randomAvailable = Math.random() * 100;

  const troves = currentContracts.troves.map((trove) => {
    return {
      name: trove.name,
      iconUrl: trove.iconUrl,
      tvl: randomTvl.toFixed(2),
      minted: randomMinted.toFixed(2),
      available: randomAvailable.toFixed(2),
      stakedApr: randomStakedApr.toFixed(2),
      yourDeposits: 0.0,
      address: trove.collateralToken,
      isEnabled: trove.isEnabled,
    };
  });

  return (
    <div className="max-w-screen-lg mx-auto flex flex-col gap-16 px-4 pb-4 mt-8 lg:mt-0 lg:px-0">
      <div className="flex justify-center text-center">
        <h1 className="font-black text-4xl">
          Choose collateral & borrow esUSD
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {troves.map((trove) => {
          return (
            <div
              className={cn("border-2 border-black rounded-xl bg-white", {
                "opacity-60 border-gray-400 bg-gray-100": !trove.isEnabled,
              })}
            >
              <div
                className={cn(
                  "border-b-2 border-black text-center flex justify-center items-center gap-2",
                  {
                    "border-gray-400": !trove.isEnabled,
                  },
                )}
              >
                <img className="h-6" src={trove.iconUrl} alt="" />
                <h2 className="text-xl py-2 font-bold">{trove.name}</h2>
              </div>
              <div className="p-4">
                <div className="grid grid-cols-2 gap-2">
                  <div className="font-bold">TVL</div>
                  <div className="text-right">{trove.tvl}m</div>
                  <div className="font-bold">Minted esUSD</div>
                  <div className="text-right">
                    {trove.minted}m / {trove.available}m
                  </div>
                  <div className="font-bold">{trove.name} APY</div>
                  <div className="text-right">{trove.stakedApr}m</div>
                  <div className="font-bold">Your deposits</div>
                  <div className="text-right">{trove.yourDeposits}</div>
                </div>
              </div>

              <div className="flex items-center pb-4 justify-center">
                <Link
                  className={cn(
                    "border-2 border-black rounded-full py-2 px-4 bg-[#70D6FF] font-bold",
                    {
                      "hover:bg-[#FF70A6]": trove.isEnabled,
                    },
                  )}
                  onClick={(e) => {
                    if (!trove.isEnabled) {
                      e.preventDefault();
                      return;
                    }
                  }}
                  to={`/${trove.address}/deposit`}
                >
                  Choose {trove.name}
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Stats() {
  return (
    <div className="bg-[#EFD6FF] w-full border-t-2 border-black mt-16 py-16">
      <div className="max-w-screen-lg mx-auto flex flex-col gap-16 mb-4 px-4">
        <div className="flex justify-center text-center items-center gap-2">
          <RollerCoaster size={33} />
          <h1 className="font-black text-4xl">Platform Pulse</h1>
        </div>
        <div className="w-full md:w-[60%] mx-auto">
          <div className="flex flex-col gap-4 w-full">
            <div className="border-2 border-black rounded-xl bg-[#B2FF8E]">
              <div className="p-4">
                <div className="grid grid-cols-2 gap-2">
                  {Object.keys(platformHealth).map((k) => {
                    return (
                      <>
                        <div className="font-bold">{k}</div>
                        <div className="text-right">{platformHealth[k]}</div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="border-2 border-black rounded-xl bg-[#FFD670]">
              <div className="p-4">
                <div className="grid grid-cols-2 gap-2">
                  {Object.keys(tokenHealth).map((k) => {
                    return (
                      <>
                        <div className="font-bold">{k}</div>
                        <div className="text-right">{tokenHealth[k]}</div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Footer() {
  return (
    <div className="bg-[#383A48] w-full border-t-2 border-black py-16 h-[282px]">
      <div className="max-w-screen-lg mx-auto flex flex-col gap-16 text-[#F7F4EF]">
        <div className="flex flex-col justify-center items-center gap-2">
          <img className="h-8" src="/ebisu-pink.svg" alt="" />
          <div className="text-sm text-center">
            <div className="flex items-center justify-center gap-2">
              <a
                className="underline"
                target="_blank"
                href="https://twitter.com/Ebisu_Money"
              >
                X/Twitter
              </a>
              <a
                className="underline"
                target="_blank"
                href="https://t.me/ebisustable"
              >
                Telegram
              </a>
              <a
                className="underline"
                target="_blank"
                href="https://ebisu.gitbook.io/ebisu-stablecoin/"
              >
                Documentation
              </a>
            </div>
          </div>
          <p className="opacity-40 text-sm">Copyright © 2023</p>
        </div>
      </div>
    </div>
  );
}

function Homepage() {
  return (
    <div
      style={{
        backgroundImage: "url(/wave.svg)",
        backgroundRepeat: "no-repeat",
      }}
      className="flex items-center justify-center h-screen flex-col gap-2 overflow-hidden bg-[size:90%] md:bg-[size:70%] bg-[position:bottom_-20px_right_-80px] md:bg-[position:bottom_-20px_right_-150px]"
    >
      <div>
        <img className="h-10" src="/ebisu.svg" alt="" />
      </div>
      <div className="text-center">
        <div>Unleash the utility of restaking in DeFi</div>
      </div>
      <div className="text-sm text-center">
        <div className="flex items-center justify-center gap-2">
          <a
            className="underline"
            target="_blank"
            href="https://twitter.com/Ebisu_Finance"
          >
            X/Twitter
          </a>
          <a
            className="underline"
            target="_blank"
            href="https://t.me/Ebisu_Finance_Updates"
          >
            Telegram
          </a>

          <a
            className="underline hidden"
            target="_blank"
            href="https://ebisu.gitbook.io/ebisu-finance"
          >
            Documentation
          </a>
        </div>
      </div>
    </div>
  );
}
