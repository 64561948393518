import { getDefaultConfig } from "connectkit";
import { Chain, configureChains, createConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";

const walletConnectProjectId = "418b185772af0b4ca6601044a993ffb2";
const buildBearChain = {
  id: 12120,
  name: "BuildBear Gleaming Raymus Antilles",
  network: "bb",
  nativeCurrency: {
    decimals: 18,
    name: "BB ETH",
    symbol: "BB ETH",
  },
  rpcUrls: {
    public: {
      http: ["https://rpc.buildbear.io/gleaming-raymus-antilles-5a38bf13"],
    },
    default: {
      http: ["https://rpc.buildbear.io/gleaming-raymus-antilles-5a38bf13"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "Buildbear",
      url: "https://explorer.buildbear.io/gleaming-raymus-antilles-5a38bf13",
    },
    default: {
      name: "Buildbear",
      url: "https://explorer.buildbear.io/gleaming-raymus-antilles-5a38bf13",
    },
  },
} as const satisfies Chain;

const { chains } = configureChains([buildBearChain], [publicProvider()]);

export const config = createConfig(
  getDefaultConfig({
    chains,
    autoConnect: true,
    appName: "Ebisu",
    walletConnectProjectId,
  }),
);
