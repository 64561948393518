import { contracts } from "@/config";

export const collaterals = [
  {
    name: "wstETH",
    iconUrl: "/tokens/steth.svg",
    tvl: 22.4,
    minted: 12.0,
    available: 15.0,
    stakedApr: 4.1,
    yourDeposits: 0.0,
    address: contracts["12120"].troves[0].collateralToken,
  },
  {
    name: "rETH",
    iconUrl: "/tokens/reth.svg",
    tvl: 29.3,
    minted: 12.0,
    available: 15.0,
    stakedApr: 3.2,
    yourDeposits: 0.0,
    address: "0x456",
  },
  {
    name: "cbETH",
    iconUrl: "/tokens/cbeth.svg",
    tvl: 21.2,
    minted: 12.0,
    available: 15.0,
    stakedApr: 6.15,
    yourDeposits: 0.0,
    address: "0x789",
  },
];

export const platformHealth: Record<string, number> = {
  "Global collateral ratio": 200,
  "Total collateral value": 78105062,
  "Total debt": 39051085,
};

export const tokenHealth: Record<string, number> = {
  "Total EBISU locked": 200,
  "Total locked weight": 0,
  "Total vote weight": 0,
};
