import { Contracts } from "@/types";

export const constants = {
  zeroAddress: "0x0000000000000000000000000000000000000000",
  maxAllowance:
    115792089237316195423570985008687907853269984665640564039457584007913129639935n,
  liquidationReserve: 200,
  mintRatio: 150,
  troveStatuses: {
    nonExistent: 0n,
    active: 1n,
    closedByOwner: 2n,
    closedByLiquidation: 3n,
    closedByRedemption: 4n,
  },
};

export const contracts: Contracts = {
  "12120": {
    core: {
      ebisuCore: "0xCA5731Bd3CE2D673FdF72C9FA8b5ae16874eA174",
      feeReceiver: "0xCd1d2fdc6800bBBd418b43ADA862DF6eAD826610",
      admin0: "0xC41Fc7c8B61A17f6dBb946Ce4e6cAe0E4e9e8E4A",
      admin1: "0xDe80b4554d012c3D43641E75997d54FD93D4b94e",
      gasPool: "0x0D79Bc83828f127a3bDfaD322F55eF79A601b874",
      sortedTroves: "0xa27f695A895F4A32227fCa3589f88D27a6fE68fd",
      stabilityPool: "0x02edf6C402B502e7E1c8494b930aB95766BFF76f",
      borrowerOperations: "0x09C94781A32C4dF86BD4F57247762445F642d3ca",
      debtToken: "0x49eFa59b9E983F82Ee56e7D9DCfEc8a94cd552dd",
      liquidationManager: "0xaB3C64c0e2804b4bc1F9D5BA796F6BcD6297BcF3",
      troveManagerImpl: "0xB81d7d2DfB2e3F5e6aAeBb2B384AbE6d0fcF1Eb9",
      factory: "0xd4C6BC4f240010D77E1aE7b5E3A4600455aeC57c",
    },
    troves: [
      {
        name: "rsETH",
        iconUrl: "/tokens/generic-color.png",
        collateralToken: "0xc6aB7Acd44d21Cb5F51cc55E3661d423fEA0343C",
        troveManager: "0xe53557BAD5A90c9247682c198be39b6BdEf3f2AE",
        isEnabled: true,
      },
      {
        name: "abcETH",
        iconUrl: "/tokens/generic.png",
        collateralToken: "0x0000000000000000000000000000000000000000",
        troveManager: "0x0000000000000000000000000000000000000000",
        isEnabled: false,
      },
      {
        name: "xyzETH",
        iconUrl: "/tokens/generic.png",
        collateralToken: "0x0000000000000000000000000000000000000000",
        troveManager: "0x0000000000000000000000000000000000000000",
        isEnabled: false,
      },
    ],
  },
};

// TODO: remove this
export const contractNames: Record<string, string> = {
  ["0xc6aB7Acd44d21Cb5F51cc55E3661d423fEA0343C"]: "wstETH",
  "0x456": "rETH",
  "0x789": "cbETH",
};
