import { AlertCircle } from "lucide-react";

export function TestnetWarning() {
  return (
    <div className="bg-yellow-100 border-b-2 border-yellow-400 p-2">
      <div className="flex items-center justify-center gap-2">
        <div className="flex-shrink-0">
          <AlertCircle className="text-yellow-700" />
        </div>
        <p className="text-yellow-700">
          You are using testnet. Follow us on{" "}
          <a
            className="underline"
            target="_blank"
            href="https://twitter.com/Ebisu_Money"
          >
            @ebisu_money
          </a>{" "}
          for updates.
        </p>
      </div>
    </div>
  );
}
