import { formatUnits } from "viem";

export function formatNumber(value: number, digits: number = 2): string {
  if (isNaN(value)) value = 0;
  else if (!isFinite(value)) return "∞";
  let str = value.toLocaleString("en-US", {
    maximumFractionDigits: digits,
  });
  if (str.indexOf(".") == -1 && digits !== 0)
    str += "." + "".padEnd(digits, "0");
  return str;
}

export function parseNumber(value: string): number {
  if (value === "") return 0;
  return parseFloat(value);
}

export function parseAndFormatNumber(
  value: string,
  digits: number = 2,
): string {
  return formatNumber(parseNumber(value), digits);
}

export function toNumber(value: bigint, digits: number = 18): number {
  return parseNumber(formatUnits(value, digits));
}
