import { ConnectKitProvider } from "connectkit";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Toaster } from "sonner";
import { WagmiConfig } from "wagmi";
import { App } from "./App";
import "./index.css";
import { config } from "./wagmi";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <WagmiConfig config={config}>
      <ConnectKitProvider>
        <Toaster />
        <App />
      </ConnectKitProvider>
    </WagmiConfig>
  </React.StrictMode>,
);
